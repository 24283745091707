/* ChatApp.css */
.chat-container {
  display: flex;
  flex-direction: column;
  max-width: 800px; /* Breder dan voorheen */
  height: 80vh;
  margin: 40px auto; /* Meer ruimte boven en onder */
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.chat-window {
  flex: 1;
  padding: 20px;
  background-color: #f1f1f1;
  overflow-y: auto;
}

.message {
  margin-bottom: 12px;
  padding: 10px 16px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  line-height: 1.4;
}

.message.user {
  background-color: #0084ff;
  color: #fff;
  align-self: flex-end;
  border-bottom-right-radius: 0;
  margin-left: auto;
}

.message.bot {
  background-color: #e5e5ea;
  color: #000;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  margin-right: auto;
}

.chat-form {
  display: flex;
  padding: 15px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.chat-input {
  flex: 1;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.2s;
}

.chat-input:focus {
  border-color: #0084ff;
}

.chat-send-button {
  margin-left: 15px;
  padding: 12px 25px;
  font-size: 16px;
  background-color: #0084ff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-send-button:hover {
  background-color: #006bbf;
}
